import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import "../styles/legal.css"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"

import awardshapeone from "../images/award-shape-one.png"
import awardshapetwo from "../images/award-shape-two.png"
import awardshapethree from "../images/award-shape-three.png"
import awardshapefour from "../images/award-shape-four.png"

const PrivacyPolicy = ({ data }) => {
  return (
    <>
      {data.allWpPage.edges.map(({ node }) => (
        <Helmet>
          <title>{node.title} | Position2</title>
          <meta name="description" content={node.seo.metaDesc} />
          <body className={node.slug} />
        </Helmet>
      ))}

      <Layout>
        <section className="legal-ani">
          <div className="awards">
            <div className="container">
                <div className="wrap">
                  <div className="img-one image-shape">
                    <img src={awardshapeone} alt="one" />
                  </div>
                  <div className="img-two image-shape">
                    <img src={awardshapetwo} alt="two" />
                  </div>
                  <div className="img-three image-shape">
                    <img src={awardshapethree} alt="three" />
                  </div>
                  <div className="img-four image-shape">
                    <img src={awardshapefour} alt="four" />
                  </div>
                  <div className="title image-shape">
                    <h3>Privacy Policy</h3>
                  </div>
                </div>
            </div>
          </div>
        </section>
        <section className="legal-pages">
          <div className="container">
              {data.allWpPage.edges.map(({ node }) => (
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      node.videoBannerWithBtitleDescriptionCta.descriptionArea,
                  }}
                ></div>
              ))}
          </div>
        </section>
      </Layout>
    </>
  )
}

export default function PrivacyPolicyBlock(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWpPage(filter: { id: { eq: "cG9zdDoyMzY0" } }) {
            edges {
              node {
                title
                slug
                id
                videoBannerWithBtitleDescriptionCta {
                  descriptionArea
                }
                seo {
                  metaDesc
                }
              }
            }
          }
        }
      `}
      render={data => <PrivacyPolicy data={data} {...props} />}
    />
  )
}
PrivacyPolicy.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}
